@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'PoppinsRegular';
  src: url(../assets/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'PoppinsExtraBold';
  src: url(../assets/fonts/Poppins-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url(../assets/fonts/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'PoppinsBold';
  src: url(../assets/fonts/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: url(../assets/fonts/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Bungee';
  src: url(../assets/fonts/Bungee-Regular.ttf) format('truetype');
}

/* dark and light theme colors */
.light {
  --headerBg: 0, 0, 0 !important;
  --theme: 235, 0, 41 !important;
  --textGray: 5, 5, 17 !important;
  --lightGray: 130, 130, 130 !important;
  --darkGray: 17, 17, 17 !important;
  --cardGray: 249, 250, 251 !important;
  --pureWhite: 0, 0, 0 !important;
  --pureBlack: 255, 255, 255 !important;
}

.dark {
  --headerBg: 255, 255, 255 !important;
  --theme: 235, 0, 41 !important;
  --textGray: 217, 217, 217 !important;
  --lightGray: 17, 17, 17 !important;
  --darkGray: 130, 130, 130 !important;
  --cardGray: 130, 125, 125 !important;
  --pureWhite: 255, 255, 255 !important;
  --pureBlack: 0, 0, 0 !important;
}

/* footer */
.footerBg {
  background-image: url('../assets/images/footer/background.svg');
  background-size: cover;
  /* height: inherit; */
}

/* Background Styles */
.blackBg {
  background-image: url('../assets/images/bg/black-bg.svg');
  background-size: cover;
  height: inherit;
}

.lightBg {
  background-image: url('../assets/images/bg/light-bg.svg');
  background-size: cover;
  height: inherit;
}

/* input style */
.nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-size: 13px; /* Change text size */
  color: var(--pureWhite);
  font-weight: 900; /* Change text color */
}

/* modal style */
.p-dialog-mask {
  backdrop-filter: blur(4px); /* Apply the blur effect */
  -webkit-backdrop-filter: blur(8px); /* Safari support */
  background-color: rgba(0, 0, 0, 0.4); /* Black background with 40% opacity */
}

/* calendar  */
.p-datepicker-group-container {
  background-color: #f5f5f5;
  color: #333;
  padding: 8px; /* Customize cell padding */
  width: 35rem; /* Text color */
}

.p-inputtext {
  background-color: transparent;
}

:root {
  --fc-button-active-bg-color: #fff4df;
  --fc-button-bg-color: #f6f6f6;
  --fc-button-active-border-color: #e3d3b5;
  --fc-button-border-color: #e3d3b5;
  --fc-button-text-color: var(--ec-alternate-bg);
  --fc-button-hover-bg-color: #e7e7e7;
  --fc-button-hover-border-color: #e3d3b5;
}

/* select component scroallbar */
.custom-select .react-select__menu-list::-webkit-scrollbar {
  width: 0px !important; /* Hide the scrollbar */
}

.custom-select .react-select__menu-list::-webkit-scrollbar-thumb {
  background-color: transparent !important; /* Hide the thumb (optional) */
}

.custom-select .react-select__menu-list::-webkit-scrollbar-track {
  background-color: transparent !important; /* Hide the track */
}

.notification-scroll::-webkit-scrollbar {
  width: 6px;
}

.notification-scroll::-webkit-scrollbar-thumb {
  background: #9d9a9a;
  border-radius: 5px;
  height: 15px;
}

.idpc-button {
  background-color: #eb0029;
  border: none;
}
