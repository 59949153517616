.idpc-input {
  border-color: gray !important;
  border: 2px !important;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  border-radius: 6px;
  color: black;
}

.idpc-select {
  color: black;
}

.idpc-button {
  background-color: red;
  margin-left: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
}

.idpc-error{
    color: red;
}